import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import React, { useState } from 'react'

const ARCCoreParents = () => {
	const [openCoreSection, setOpenCoreSection] = useState(false)
	return (
		<>
			<Row center>
				<Col width="w-full">
					<h2 className="mb-2 text-4xl font-bold text-ab-100">ARC Core&#174;</h2>
					<div style={{ height: `${openCoreSection ? 'auto' : '256px'}`, overflow: `${openCoreSection ? 'inital' : 'hidden'}`, position: 'relative' }}>
						<p className="text-lg text-slate-400 sm:text-xl">
							ARC Core is a literacy curriculum that is designed to create a lifelong love of reading at home and at school through access to a wide variety of rich
							texts. ARC Core prepares students for college or a career, and to become responsible citizens in a democracy. Loving reading is not innate—children{' '}
							<i>learn</i> to love books from their families and their teachers. Students who learn to love reading read enough to get good at it.
						</p>

						<p className="text-lg text-slate-400 sm:text-xl">
							Our approach asks teachers to identify the one essential skill that a student needs next in order to move reading levels, help them learn it, and repeat
							that cycle until they're reading at or above grade level. You can read more about our leveling system.
						</p>

						<p className="text-lg text-slate-400 sm:text-xl">
							The student's level is determined by a teacher or reading specialist using ARC's formative assessment known as the IRLA. The skills that students need
							to master are monitored in our achievement dashboard known as SchoolPace.
						</p>

						<p className="text-lg text-slate-400 sm:text-xl">ARC Core is divided into four units throughout each school year:</p>
						<ul className="text-lg text-slate-400 sm:text-xl">
							<li className="mb-6">
								<b>Unit 1: ARC Literacy Lab</b>
								<br></br>Building a Community of Avid Readers & Writers
							</li>
							<li className="mb-6">
								<b>Unit 2: Informational Reading</b>
								<br></br>Writing & Research in One Science Topic
							</li>
							<li className="mb-6">
								<b>Unit 3: Literature Reading</b>
								<br></br>Writing & Analysis in One Literary Genre
							</li>
							<li className="mb-6">
								<b>Unit 4: Argument Writing</b>
								<br></br>Writing & Research in One Social Studies Topic
							</li>
						</ul>

						<p className="text-lg text-slate-400 sm:text-xl">
							The thematic units of ARC Core are both intentionally multicultural and intersectional in their approach to cultures. The texts in ARC's book
							collections present multiple perspectives and traditionally underrepresented narratives that are scientifically and historically accurate, informative,
							and engaging. As an example, you can watch highlights of an ARC webinar that discusses how Cinderella stories differ based on the culture they're
							rewritten for.
						</p>

						<div
							className={`white-transparent-gradient ${
								openCoreSection ? 'z-[-1] opacity-0' : 'z-10 opacity-100'
							} absolute top-0 flex w-full items-end justify-center transition-opacity duration-200 ease-in-out`}
							style={{ height: 256 }}
						></div>
					</div>
					<div className="mt-10">
						<Button size="md" onClick={() => setOpenCoreSection(!openCoreSection)}>
							{openCoreSection ? 'Collapse Section' : 'Read More'}
						</Button>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default ARCCoreParents
