import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import OverlayVideo from 'components/overlay-video'
import React, { useState } from 'react'
import Resource from '../global/Resource'

const FSTKsParents = () => {
	const [openFSTKSection, setOpenFSTKSection] = useState(false)
	return (
		<>
			<Row center>
				<Col width="w-full">
					<h2 className="mb-2 text-4xl font-bold text-ab-100">IRLA & Foundational Skills Toolkits</h2>
					<div style={{ height: `${openFSTKSection ? 'auto' : '256px'}`, overflow: `${openFSTKSection ? 'inital' : 'hidden'}`, position: 'relative' }}>
						<p className="text-lg text-slate-400 sm:text-xl">
							The <b>IRLA</b> or Independent Reading Level Assessment is a tool that helps teachers diagnose what skills a reader has and what they most need to learn
							next. The teacher meets with the student individually at regular intervals to assess the student's skills. You can see an example of this process below.
						</p>

						<Row className="m-10">
							<Col className="mx-auto text-center sm:w-full md:w-1/2">
								<Resource title="IRLA" noLink noZoom maxHeight={325} />
							</Col>
							<Col className="mx-auto text-center sm:w-full md:w-1/2">
								<OverlayVideo title="ARC Core K-12 Assessment" />
							</Col>
						</Row>

						<p className="text-lg text-slate-400 sm:text-xl">
							The <b>ENIL</b> or Evaluación de nivel independiente de lectura, is ARC's assessment tool for Spanish language learners.
						</p>

						<Row className="m-10">
							<Col className="mx-auto text-center sm:w-full md:w-1/2">
								<Resource title="ENIL | CCSS" noLink noZoom maxHeight={325} />
							</Col>
							<Col className="mx-auto text-center sm:w-full md:w-1/2">
								<OverlayVideo title="Leonardo Aponte ENIL" />
							</Col>
						</Row>

						<p className="text-lg text-slate-400 sm:text-xl">
							The <b>IRLA Toolkits</b> offer instructional resources based on students' needs including foundational skills lessons for students who need them. The
							Toolkits provide mini-lessons and instructional strategies to help teachers facilitate instruction individually, in pairs, or in small groups.
						</p>

						<Row className="m-10">
							<Col>
								<img
									src="https://images.ctfassets.net/xo7k5gkhtsb4/6tT5na1RkXIBUhKD57MF4q/fcbab270f99174e10dad24673b3fdad1/FSTK_Handbook___Basket___Wt.png"
									title="FSTK Handbook & Basket | Wt"
									className="mx-auto text-center sm:w-full md:max-w-lg"
									alt="FSTK basket"
								/>
							</Col>
						</Row>

						<div
							className={`white-transparent-gradient ${
								openFSTKSection ? 'z-[-1] opacity-0' : 'z-10 opacity-100'
							} absolute top-0 flex w-full items-end justify-center transition-opacity duration-200 ease-in-out`}
							style={{ height: 256 }}
						></div>
					</div>
					<div className="mt-10">
						<Button size="md" onClick={() => setOpenFSTKSection(!openFSTKSection)}>
							{openFSTKSection ? 'Collapse Section' : 'Read More'}
						</Button>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default FSTKsParents
