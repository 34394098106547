import { Link } from 'gatsby'
import { Accordion, AccordionItem } from 'components/accordion'
import Resource from 'components/global/Resource'
import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const PlPrograms = () => {
	return (
		<Row overflow={false}>
			<Col>
				<Tabs selectedTabClassName="text-ab-100 border-ab-100">
					<TabList className="mb-6 flex flex-wrap">
						<Tab className="mx-4 mb-6 flex-grow cursor-pointer rounded border px-3 py-5 text-center text-base shadow-sm lg:text-lg">ARC University</Tab>
						<Tab className="mx-4 mb-6 flex-grow cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-lg">Leadership Learning Series</Tab>
						<Tab className="mx-4 mb-6 flex-grow cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-lg">Multilingual Learners</Tab>
						<Tab className="mx-4 mb-6 flex-grow cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-lg">Learning Lab</Tab>
					</TabList>
					<TabPanel>
						<div>
							<span className="mx-auto mb-2 block w-full max-w-2xl text-center text-3xl text-ab-100 sm:text-4xl">Build Practice. Earn Badges. See Results.</span>

							<div className="py-4 px-8">
								<Row>
									<Col>
										<Carousel showThumbs={false} showStatus={false} infiniteLoop>
											<div>
												<p className="mx-auto max-w-4xl text-center text-lg leading-loose text-slate-400">
													ARC University engages educators in high-quality, curriculum-aligned asynchronous professional learning courses within a virtual
													community, supported by ARC Executive Coaches.
												</p>

												<p className="mx-auto max-w-4xl text-center text-lg leading-loose text-slate-400">
													Foundations of the ARC Literacy Lab<sup>®</sup> is a six-week course designed to provide systematic, accelerated onboarding for
													new ARC Core<sup>®</sup> teachers in Grades 3–8 and to support educators in need of a refresher. Our virtual learning community
													offers a flexible and responsive course of action to optimize learning.
												</p>
											</div>
											<div>
												<p className="mx-auto max-w-4xl text-left text-lg leading-loose text-slate-400">
													By the end of the ARC University course, participants will be able to:
												</p>
												<div className="text-left">
													<ul className="mx-auto ml-14 max-w-4xl list-disc text-slate-400">
														<li>Implement transformative literacy practices within a culturally and linguistically responsive pedagogy.</li>
														<li>
															Use the ARC Literacy Lab framework and relevant tools to plan lessons that result in immediate classroom application.
														</li>
														<li>Set up effective routines to foster daily, engaged, and accountable Independent Reading.</li>
														<li>Set up effective routines to foster daily, engaged, and accountable Independent Reading.</li>
														<li>
															Use appropriate tools to conduct conferences with students for a variety of purposes: to foster reading
															relationships/engagement, to determine baseline reading levels, and to coach Power Goals.
														</li>
														<li>Use available tools and resources to plan and facilitate flexible, responsive small-group conferences.</li>
														<li>
															Record evidence of learning in SchoolPace<sup>®</sup> and use data to create Action Plans to increase the rate of
															reading growth for each student.
														</li>
													</ul>
												</div>
											</div>
											<div>
												<p className="mx-auto max-w-4xl text-center text-lg font-bold leading-loose text-slate-400">
													Foundations of the ARC Literacy Lab: Course Outline
												</p>

												<p className="mx-auto max-w-4xl text-center text-lg leading-loose text-slate-400">
													This course is organized into a series of six self-directed modules. As participants demonstrate mastery of the learning goals
													for each module, they will earn badges. Badges accrue throughout the course and result in a Foundations of the ARC Literacy Lab
													microcredential.
												</p>
												<img src="/images/professional-learning/arcu-outline.png" alt="ARC University Course Outline" className="max-w-xl" />
											</div>
											<div>
												<p className="mx-auto max-w-2xl text-center text-2xl">
													“While so much about instruction has become impersonal this year, ARC has continued to add the personal touch to this important
													work. Through the Foundations of the ARC Literacy Lab course, teachers were guided every step of the way through regular
													check-ins and one-on-one interaction with an ARC coach.”
												</p>
												<p>—Todd Cayton, Literacy Supervisor, Guilford County Schools, NC</p>
											</div>
										</Carousel>
									</Col>
								</Row>
							</div>
						</div>
					</TabPanel>
					<TabPanel>
						<Row>
							<Col className="flex flex-col items-center">
								<Resource title="What Does a Year Look Like? | Grade 5" shadow noZoom />
								<p>Click to Download as a PDF</p>
							</Col>
						</Row>
					</TabPanel>
					<TabPanel>
						<Row>
							<Col className="flex flex-col items-center">
								<Resource title="What Does a Day Look Like?" shadow noZoom />
								<p>Click to Download as a PDF</p>
							</Col>
						</Row>
					</TabPanel>
					<TabPanel>
						<div className="px-4">
							<Accordion>
								<AccordionItem title="Professional Learning" top>
									<p className="mx-auto max-w-3xl text-center text-lg text-slate-400">
										American Reading Company offers virtual Professional Learning that equips teachers and leaders to meet the changing needs of learners across
										platforms.
									</p>
									<Row className="py-4" center>
										<Col width="w-full xl:w-7/12">
											<Row>
												<Col width="w-1/3" className="py-4">
													<Resource title="Virtual PD" shadow text="Virtual PD" />
												</Col>
												<Col width="w-1/3" className="py-4">
													<Resource title="ARC University" shadow text="ARC University" />
												</Col>
												<Col width="w-1/3" className="py-4">
													<Resource title="Leadership Learning PD" shadow text="Leadership Learning PD" />
												</Col>
											</Row>
										</Col>
									</Row>
								</AccordionItem>
								<AccordionItem title="Digital Supports">
									<Row className="py-4">
										<p className="mx-auto mb-5 max-w-3xl text-center text-lg text-slate-400">
											ARC Core digital supports, including interactive digital frameworks, thematic eLibraries, and sample pre-recorded lessons or Read
											Alouds, are available with a SchoolPace subscription.
										</p>
										<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
											<img src="/images/arc-core/schoolpace-connect-screenshot.png" alt="schoolpace connect screenshot" />
										</Col>
										<Col width="w-full md:w-1/2" className="mb-6 flex items-center md:mb-0">
											<img src="/images/arc-core/thematic-elibrary.png" alt="elibraries" />
										</Col>
										<Col>
											<div className="border-4 border-dashed border-ab-100 p-2 sm:p-5">
												<Row className="items-center">
													<Col width="w-full md:w-1/3" className="mb-3 sm:mb-0">
														<img src="/images/logos/schoolpace-connect-logo.svg" alt="schoolpace connect logo" />
													</Col>
													<Col width="w-full md:w-2/3" className="pl-4 sm:pl-6">
														<span className="mb-3 inline-block text-lg font-semibold sm:text-xl">
															An enhanced platform for ELA content delivery, providing access to:
														</span>
														<ul className="list-disc pl-2 text-lg text-slate-400 sm:pl-6">
															<li>
																Digital Foundational Skills Toolkits<sup>®</sup> with sample pre-recorded lessons (Y&#8211;2R/A&#8211;2R)
															</li>
															<li>Wide Reading eLibraries (Y&#8211;Pu/A&#8211;Pú)</li>
															<li>
																<i>ARC Adventures</i>
																<sup>®</sup>
															</li>
															<li>ARC Core digital supports for select themes and grades</li>
														</ul>
														<div className="mb-4 mt-6 flex md:mb-0 md:mt-0 md:justify-end">
															<Link to="/digital-solutions">
																<Button>Learn More</Button>
															</Link>
														</div>
													</Col>
												</Row>
											</div>
										</Col>
									</Row>
								</AccordionItem>
								<AccordionItem title="Classroom Modules Packaged for Social Distancing">
									<Row>
										<p className="mx-auto mb-5 max-w-3xl text-center text-lg text-slate-400">
											We now offer ARC Core modules packaged to support instruction when students can't share books in the classroom. These modules include
											traditional Core Texts for shared reading and Lab Notebooks with daily student-facing reading and writing activities.
										</p>
										<Col width="w-full md:w-1/2" className="my-6">
											<span className="mb-3 inline-block w-full text-center text-lg sm:text-xl">Teacher Materials</span>
											<img
												src="/images/arc-core/social-distance-module-1a.png"
												alt="social distance module"
												className="border-4 border-dashed border-ab-100"
											/>
										</Col>
										<Col width="w-full md:w-1/2" className="my-6">
											<span className="mb-3 inline-block w-full text-center text-lg sm:text-xl">Student Materials</span>
											<img
												src="/images/arc-core/social-distance-module-2a.png"
												alt="social distance module"
												className="border-4 border-dashed border-ab-100"
											/>
										</Col>
									</Row>
								</AccordionItem>
								<AccordionItem title="Add-On Packages" bottom>
									<Row>
										<p className="mx-auto mb-5 max-w-3xl text-center text-lg text-slate-400">
											If you've already purchased traditional ARC Core modules, we offer add-on packages to support blended and at-home learning.
										</p>
										<Col width="w-full md:w-1/2" className="my-6">
											<span className="mb-3 inline-block w-full text-center text-lg sm:text-xl">Class Set of Shared Reading Texts & Notebooks</span>
											<img src="/images/arc-core/add-on-packages-1.png" alt="social distance module" className="border-4 border-dashed border-ab-100" />
										</Col>
										<Col width="w-full md:w-1/2" className="my-6">
											<span className="mb-3 inline-block w-full text-center text-lg sm:text-xl">Class Set of Notebooks Only</span>
											<img src="/images/arc-core/add-on-packages-2.png" alt="social distance module" className="border-4 border-dashed border-ab-100" />
										</Col>
									</Row>
								</AccordionItem>
							</Accordion>
						</div>
					</TabPanel>
				</Tabs>
			</Col>
		</Row>
	)
}

export default PlPrograms
