import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import React, { useState } from 'react'

const HBCParents = () => {
	const [openHBCSection, setOpenHBCSection] = useState(false)
	return (
		<>
			<Row center>
				<Col width="w-full">
					<h2 className="mb-2 text-4xl font-bold text-ab-100">100 BOOK CHALLENGE&#174;</h2>
					<div style={{ height: `${openHBCSection ? 'auto' : '256px'}`, overflow: `${openHBCSection ? 'inital' : 'hidden'}`, position: 'relative' }}>
						<p className="text-lg text-slate-400 sm:text-xl">
							100 BOOK CHALLENGE is an independent reading program designed to maximize the effectiveness of a school’s reading curriculum. Its goal is to develop
							avid, lifelong readers, students whose proficiency in and love of reading enable them to flourish throughout their lives—in the classroom, on
							standardized tests, at the college of their choice, and in a life-enriching career—allowing all aspects of their experience to be enhanced by the
							information and perspectives gained from reading.
						</p>

						<p className="text-lg text-slate-400 sm:text-xl">
							100 BOOK CHALLENGE does this by dramatically increasing the amount of reading a student is expected to do. The program’s high standards require a
							minimum of 30 minutes of independent reading a day in school and an additional 30 minutes of reading at home. It provides each student with a selection
							of hundreds of books matched to their reading level (each reading level is designated by a different color) and allows students to choose the books they
							want to read.
						</p>

						<p className="text-lg text-slate-400 sm:text-xl">
							Students track the amount of reading they complete on logsheets that are signed by parents and are reviewed daily by their teacher. Teachers regularly
							hold individual conferences with students to be sure they are engaged with their books, to assess the students’ understanding of the material they’ve
							read, and to set goals for future reading.
						</p>

						<div className="mx-auto mb-10 text-center">
							<a href="https://www.americanreading.com/products/100bc/faq/">
								<Button>100 BOOK CHALLENGE FAQs</Button>
							</a>
						</div>

						<div
							className={`white-transparent-gradient ${
								openHBCSection ? 'z-[-1] opacity-0' : 'z-10 opacity-100'
							} absolute top-0 flex w-full items-end justify-center transition-opacity duration-200 ease-in-out`}
							style={{ height: 256 }}
						></div>
					</div>
					<Button size="md" onClick={() => setOpenHBCSection(!openHBCSection)}>
						{openHBCSection ? 'Collapse Section' : 'Read More'}
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default HBCParents
