import { Col, Row } from 'components/grid'
import React from 'react'

const SchoolPaceParents = () => {
	return (
		<>
			<Row center>
				<Col width="w-full">
					<h2 className="text-ab-100 text-4xl font-bold mb-2">SchoolPace</h2>
					<p className="text-lg sm:text-xl text-slate-400">
						SchoolPace is a web-based dashboard that allows a school district the ability to monitor student growth and performance in real-time.
					</p>
					<img src="/images/digital-solutions/bilingual-school-dashboard.jpg" className="sm:w-full md:max-w-xl mx-auto m-10" alt="dashboard" />
				</Col>
			</Row>
		</>
	)
}

export default SchoolPaceParents
