import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import PlPrograms from 'components/professional-learning/PlPrograms'
import React from 'react'
// @ts-ignore
import Slide from 'react-reveal/Slide'

import ARCCoreParents from '../components/parents/ARCCoreParents'
import FSTKsParents from '../components/parents/FSTKsParents'
import HBCParents from '../components/parents/HBCParents'
import ParentFAQs from '../components/parents/ParentFAQs'
import Resources from '../components/parents/Resources'
import SchoolPaceParents from '../components/parents/SchoolPaceParents'
import LargeForm from 'components/forms/LargeForm'

export default function ParentsPage() {
	return (
		<Layout pageName="Parents">
			<Section flatGray>
				<Row middle className="pb-12 pt-12 md:pb-16 md:pt-0">
					<Col width="w-full md:w-1/2">
						<Slide left cascade>
							<div>
								<h1 className="mb-3 text-3xl font-medium leading-tight tracking-normal sm:mb-6 sm:text-4xl md:text-5xl">Welcome to the ARC Family.</h1>
								<p className="text-lg text-slate-400 sm:text-xl">Here you can find resources to support and accelerate your child's literacy education.</p>
							</div>
						</Slide>
					</Col>
					<Col width="w-full md:w-1/2" className="hidden md:block">
						<Slide right>
							<img src="/images/parents/family-reading.png" alt="family reading" className="hidden sm:block" />
						</Slide>
					</Col>
				</Row>
			</Section>
			<Section margin="sm:mt-10">
				<ARCCoreParents />
			</Section>
			<Section margin="sm:mt-10">
				<HBCParents />
			</Section>
			<Section margin="sm:mt-10" id="IRLA">
				<FSTKsParents />
			</Section>
			<Section margin="sm:mt-10" id="SchoolPace">
				<SchoolPaceParents />
			</Section>
			<Section margin="sm:mt-10">
				<Resources />
			</Section>
			<Section margin="sm:mt-10">
				<ParentFAQs />
			</Section>
			<Section margin="mt-20 sm:mt-30 px-10">
				<p className="mx-auto text-center sm:text-4xl md:text-5xl">ARC's overall rating from Professional Learning participants was 4.4 out of 5 stars</p>
				<p className="text-center text-sm">Data as of February 3, 2021</p>
			</Section>
			<Section margin="mt-20 sm:mt-30">
				<PlPrograms />
			</Section>
			<Section margin="mt-20 sm:mt-30" id="watch">
				<h1 className="mb-3 text-3xl font-medium leading-tight tracking-normal sm:mb-6 sm:text-4xl md:text-5xl">Connect with the ARC Community</h1>
				<p>
					<a href="https://www.facebook.com/americanreading" target="_new">
						Facebook
					</a>{' '}
					|{' '}
					<a href="https://twitter.com/AmericanReading" target="_new">
						Twitter
					</a>
				</p>
				<Row className="items-center">
					<Col width="w-full md:w-7/12" className="">
						<Slide left>
							<a href="https://www.americanreading.com/webinars" target="_blank" rel="noopener noreferrer">
								<div className="-mr-0">
									<img src="/images/professional-learning/webinar.png" alt="American Reading Company Webinars" />
								</div>
							</a>
						</Slide>
					</Col>
					<Col width="w-full md:w-5/12" className="bg-white py-0 pl-8 sm:bg-slate-50 md:py-40">
						<Slide right>
							<span className="mt-3 mb-1 inline-block text-3xl font-medium">Connect With the ARC Community</span>
							<p className="mb-3 text-lg text-slate-400">Find more information and other engaged parents via FaceBook Live chats and our social media channels.</p>
						</Slide>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-40">
				<Row className="justify-center pb-6">
					<Col width="w-full md:w-5/6 lg:w-3/4 xl:w-1/2">
						<LargeForm pageName="Parents" />
					</Col>
				</Row>
			</Section>
			<Section>
				<Row>
					<Col>
						<span className="inline-block w-full text-center">
							<a href="https://www.schoolpace.com/privacy.html" target="_blank" rel="noopener noreferrer">
								SchoolPace Privacy Policy
							</a>
						</span>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
