import { Col, Row } from 'components/grid'
import React from 'react'

const ParentFAQs = () => {
	return (
		<>
			<Row center>
				<Col width="w-full">
					<h2 className="text-ab-100 text-4xl font-bold mb-2">FAQs for Families</h2>
					<p className="text-lg sm:text-xl text-slate-400">
						Certified American Reading Company Executive Coaches work shoulder-to-shoulder with school- and district-level Leadership Teams early and often to ensure
						that achievement targets are met.
					</p>
				</Col>
			</Row>
		</>
	)
}

export default ParentFAQs
